.wrapper {
  position: relative;
  margin-bottom: 24px;
  width: 100%;

  &Inner {
    position: relative;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 16px;
    cursor: text;
    height: 56px;
    max-height: 56px;
    transition: all 0.2s ease-in-out;
    border: 1px solid var(--content-thirdary);
  }

  &--error {
    .wrapperInner {
      border: 1px solid var(--status-red);
    }
  }

  &--disabled {
    .label {
      color: var(--content-thirdary);

      span {
        color: var(--content-thirdary);
      }
    }

    .input {
      user-select: none;

      &Wrapper {
        cursor: not-allowed;
      }
    }

    .icon {
      opacity: 0.3;
    }

    .prefix {
      cursor: not-allowed;
      color: var(--field-placeholder);
    }
  }

  &--focused {
    .wrapperInner {
      box-shadow: 0 0 12px var(--field-focus-shadow);
      border: 1px solid var(--brand-secondary);
    }
  }

  &--readonly {
    .wrapperInner {
      background-color: #fffae7;
      border: 1px solid var(--status-orange);
      box-shadow: none;
    }
  }

  &--type {
    &-textarea {
      .wrapperInner {
        padding: 0;
        height: auto;
        max-height: none;
      }
    }

    &-textEditor {
      .wrapperInner {
        padding: 0;
        height: auto;
        max-height: none;
        flex-direction: column;

        :global(.ql-editor) {
          min-height: 200px;
        }

        :global(.ql-toolbar.ql-snow) {
          border: 0;
          border-bottom: 1px solid var(--content-thirdary);
        }

        :global(.ql-container.ql-snow) {
          border: 0;
        }
      }
    }
  }
}

.textEditor {
  height: auto;
  cursor: inherit;
  overflow: auto;
  padding: 0;

  &Toolbar {
    width: 100%;
  }
}

.label {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: var(--content-primary);
  cursor: pointer;
  z-index: 1;
  user-select: none;
  margin-bottom: 8px;

  span {
    color: var(--status-red);
    margin-right: 8px;
  }

  @media (max-width: 992px) {
    white-space: normal;
  }
}

.input {
  background-color: transparent;
  border: 0;
  outline: none;
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: var(--content-primary);
  transition: all 0.2s ease-in-out;
  padding: 0;
  height: 24px;
  overflow: hidden;
  cursor: inherit;

  &::placeholder {
    font-size: 16px;
    line-height: 140%;
    color: var(--field-placeholder);
    -webkit-text-fill-color: var(--field-placeholder);
  }

  &:disabled {
    color: var(--field-placeholder);
    -webkit-text-fill-color: var(--field-placeholder);

    &::placeholder {
      color: var(--field-placeholder);
      -webkit-text-fill-color: var(--field-placeholder);
    }
  }
}

.textarea {
  height: auto;
  cursor: inherit;
  overflow: auto;
  padding: 16px;
}

.prefix,
.suffix {
  font-size: 16px;
  line-height: 140%;
  height: 24px;
  display: flex;
  align-items: center;
  position: relative;
}

.prefix {
  margin-right: 10px;
  padding-right: 10px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    height: 32px;
    background-color: var(--background-line);
  }
}

.suffix {
  margin-left: 10px;
  padding-left: 10px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    height: 32px;
    background-color: var(--background-line);
  }
}

.icon {
  width: fit-content;
  min-width: 28px;
  height: 28px;
  background: var(--background-secondary);
  border-radius: 6px;
  padding: 4px;
  color: var(--content-primary);
  margin-left: 10px;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  &Disabled {
    opacity: 0.4;
    cursor: not-allowed !important;
    background-color: var(--background-secondary);
    color: var(--content-secondary);

    svg {
      path {
        fill: var(--content-secondary);
      }
    }
  }

  &Clickable {
    cursor: pointer;
    user-select: none;
    width: fit-content;
    min-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.errorLabel {
  position: absolute;
  left: 0;
  top: 100%;
  padding-top: 5px;
  display: block;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  color: var(--status-red);
}
